import React, { useState } from "react";
import Markdown from "react-markdown";
import ExternalLink from "./ExternalLink";
import { Tooltip } from "react-tooltip";

const Card = ({ data }) => {
    const [open, setOpen] = useState(false);
    const showMore = data.extended_text || data.extended_list;

    const getCounts = (data) => {
        const day = 1000 * 60 * 60 * 24;

        const start = new Date(data.start).getTime();
        const end = data.end ? new Date(data.end) : Date.now();
        const diff = end - start;

        const days_total = diff / day;
        // 365 days per year, 104 are weekends, 10 public holidays, 20 days leave, 5 days sick
        // = 226 work days
        const days_worked = days_total * (226 / 365);
        const hours_worked = days_worked * (data.hours_per_day ?? 8);

        return `${Math.round(
            days_worked,
        ).toLocaleString()} work days ~ ${Math.round(
            hours_worked,
        ).toLocaleString()} work hours`;
    };

    return (
        <div
            className="card margin-bottom-large"
            onClick={() => setOpen(!open)}
        >
            {data.image && <img src={data.image} alt="" />}
            <div className="card-body">
                <div className="row flex-edges card-title-container">
                    <div className="row card-title-logo">
                        {data.logo && <i className={`${data.logo} cv-logos`} />}
                        {data.title && (
                            <>
                                <h4 className="card-title">
                                    {data.title}
                                    {data.link && (
                                        <span data-tooltip-id={data.link}>
                                            <ExternalLink href={data.link}>
                                                {data.link_text}
                                            </ExternalLink>
                                        </span>
                                    )}
                                </h4>
                                {data.link && (
                                    <Tooltip
                                        id={data.link}
                                        place="bottom"
                                        content={data.link_short}
                                    />
                                )}
                            </>
                        )}
                    </div>
                    {data.subtitle && (
                        <h5 className="card-subtitle primary-colour">
                            {data.subtitle}
                        </h5>
                    )}
                </div>
                {data.text && (
                    <div className="card-text">
                        <Markdown>{data.text}</Markdown>
                    </div>
                )}
                {!open && showMore && (
                    <h5 className="text-secondary margin-bottom-none margin-top-none secondary-colour">
                        read more...
                    </h5>
                )}
                <div className={open ? "fadeIn" : "fadeOut"}>
                    {data.extended_text && (
                        <div className="card-text">
                            <Markdown>{data.extended_text}</Markdown>
                        </div>
                    )}
                    {data.extended_list && (
                        <Markdown>
                            {"* " + data.extended_list.join(" \n * ")}
                        </Markdown>
                    )}
                    {data.start && getCounts(data)}
                </div>
            </div>
        </div>
    );
};

export default Card;
