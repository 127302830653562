import React from "react";
import ExternalLink from "./ExternalLink";

const Footer = () => {
    return (
        <p className="text-muted footer-text">
            Made in{" "}
            <ExternalLink href="https://react.dev/">React.js</ExternalLink> with{" "}
            <ExternalLink href="https://getpapercss.com/">
                Paper.css
            </ExternalLink>
        </p>
    );
};

export default Footer;
