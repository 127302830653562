import React, { useContext } from "react";
import { DarkModeContext } from "./DarkModeProvider";

const DarkModeSwitch = () => {
    const { darkMode, setDarkMode } = useContext(DarkModeContext);

    return (
        <fieldset className="form-group">
            <label htmlFor="paperSwitch11" className="paper-switch-2-label">
                <i
                    className={`fa-regular dark-mode-logo ${
                        darkMode ? "fa-moon" : "fa-sun"
                    }`}
                />
            </label>
            <label className="paper-switch-2">
                <input
                    id="paperSwitch11"
                    name="paperSwitch11"
                    type="checkbox"
                    checked={darkMode}
                    onChange={() => setDarkMode(!darkMode)}
                />
                <span className="paper-switch-slider round" />
            </label>
        </fieldset>
    );
};

export default DarkModeSwitch;
