import React from "react";
import Card from "../components/Card";
import config from "../data.json";
import Logos from "../components/Logos";
import ExternalLink from "../components/ExternalLink";
import { Tooltip } from "react-tooltip";

const About = () => {
    return (
        <div className="row flex-edges cv-div">
            <div className="sm-8 col">
                <Card data={config.intro} />
                <h4 className="margin-top-none card-title">Employment</h4>
                {config.experience.map((data) => (
                    <Card data={data} key={data.title} />
                ))}
                <h4 className={"card-title"}>Education</h4>
                {config.education.map((data) => (
                    <Card data={data} key={data.title} />
                ))}
            </div>
            <div className="sm-4 col">
                {/*<i data-tooltip-id={"CodeWars"}>*/}
                {/*    <ExternalLink*/}
                {/*        href={"https://www.codewars.com/users/real-casey-james"}*/}
                {/*    >*/}
                {/*        <img*/}
                {/*            src={*/}
                {/*                "https://www.codewars.com/users/real-casey-james/badges/large"*/}
                {/*            }*/}
                {/*        />*/}
                {/*    </ExternalLink>*/}
                {/*</i>*/}
                {/*<Tooltip id={"CodeWars"} place="bottom" content={"CodeWars"} />*/}
                {config.sidebar.map((data, i, a) => {
                    const category = Object.keys(a[i])[0];
                    return (
                        <Logos
                            data={data[category]}
                            category={category}
                            key={category}
                        />
                    );
                })}
            </div>
        </div>
    );
};
// todo add brief work history pre bgi
// todo mention certificates, achievements, add codewars embed?
// todo bold keywords
export default About;
