import React from "react";
import files from "./LogoFiles";
import { Tooltip } from "react-tooltip";
import Badge from "./Badge";

const Logo = ({ data, className }) => {
    return (
        <>
            {(data.logo || data.SVG) && (
                <i
                    data-tooltip-id={data.name}
                    className={`${data.logo ?? ""} logo-logos`}
                >
                    {files[data.SVG] ?? ""}
                </i>
            )}

            {data.text && (
                <div className="logo-badge-container">
                    <Badge
                        className={
                            className +
                            " margin-small logo-badge logo-logos logo-badges primary"
                        }
                        text={data.text}
                        secondary={false}
                    />
                </div>
            )}

            {!data.text && (
                <Tooltip id={data.name} place="bottom" content={data.name} />
            )}
        </>
    );
};

export default Logo;
