import React from "react";

const Badge = ({ text, className = "", secondary = true }) => {
    return (
        <span
            className={`badge shadow ${
                secondary ? "secondary" : ""
            } ${className}`}
        >
            {text}
        </span>
    );
};

export default Badge;
