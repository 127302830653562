import { Outlet, useLocation } from "react-router-dom";
import Nav from "../components/Nav";
import React, { useEffect } from "react";
import Footer from "../components/Footer";

const Layout = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            {pathname !== "/" && <Nav />}
            <Outlet />
            <Footer />
        </>
    );
};

export default Layout;
