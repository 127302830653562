import React from "react";
import { Link } from "react-router-dom";
import DarkModeSwitch from "./DarkModeSwitch";
import ExternalLink from "./ExternalLink";
import Badge from "./Badge";
import { Tooltip } from "react-tooltip";
import config from "../data.json";

const Nav = () => {
    return (
        <>
            <nav className="fixed split-nav shadow padding-small">
                <div className="nav-brand">
                    <h3>
                        <Link to="/">Casey James</Link>
                    </h3>
                    <h4 style={{ marginLeft: "20px" }}>
                        <Badge
                            className="logo-badge primary"
                            text="Software Engineer"
                            secondary={false}
                        />
                    </h4>
                </div>
                <div className="collapsible">
                    <input
                        id="collapsible1"
                        type="checkbox"
                        name="collapsible1"
                    />
                    <label htmlFor="collapsible1" className="burger">
                        {[1, 2, 3, 4, 5].map((num) => (
                            <div className={`bar${num}`} key={num} />
                        ))}
                    </label>
                    <div className="collapsible-body">
                        <ul className="inline row menubar">
                            {config.home_logos.map((logo) => (
                                <li key={logo.name}>
                                    <ExternalLink
                                        href={logo.href}
                                        className="logo-links"
                                    >
                                        <i
                                            data-tooltip-id={logo.name}
                                            className={`${logo.logo} badge-links nav-logo-links secondary-colour`}
                                        />
                                    </ExternalLink>
                                </li>
                            ))}
                            {/*<li style={{marginRight: "15px"}}><Link to="/blog">Blog</Link></li>*/}
                            {/*<li style={{marginRight: "20px"}}><Link to="/about">About</Link></li>*/}
                            <DarkModeSwitch />
                        </ul>
                    </div>
                </div>
            </nav>
            {config.home_logos.map((logo) => (
                <Tooltip
                    id={logo.name}
                    key={logo.name}
                    place="bottom"
                    content={logo.link_short}
                />
            ))}
        </>
    );
};

export default Nav;
