import React from "react";

const ExternalLink = ({ href, className = "", children }) => {
    const handleClick = (e) => {
        e.stopPropagation();
    };

    return (
        <a
            href={href}
            target="_blank"
            className={className}
            rel="noreferrer"
            onClick={handleClick}
        >
            {children}
        </a>
    );
};

export default ExternalLink;
