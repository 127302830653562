import { createContext, useEffect, useState } from "react";

export const DarkModeContext = createContext(null);

const DarkModeProvider = ({ children }) => {
    const [darkMode, setDarkMode] = useState(
        window.matchMedia("(prefers-color-scheme: dark)")?.matches,
    );

    document.documentElement.className = darkMode ? "dark" : "";

    useEffect(() => {
        if (!window.matchMedia) {
            return;
        }

        const query = window.matchMedia("(prefers-color-scheme: dark)");

        setDarkMode(query.matches);

        query.addEventListener("change", (event) => setDarkMode(event.matches));
    }, []);

    return (
        <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>
            {children}
        </DarkModeContext.Provider>
    );
};

export default DarkModeProvider;
