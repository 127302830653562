import React from "react";
import Logo from "./Logo";

const Logos = ({ data, className = "", category }) => {
    return (
        <div className="card margin-bottom-large">
            <div className="card-body">
                <h4 className="card-title">{category}</h4>
                <div className="row flex-spaces">
                    {data.map((d) => (
                        <Logo
                            data={d}
                            className={className}
                            key={d.logo ?? d.SVG ?? d.text}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Logos;
