import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { inject } from "@vercel/analytics";

import "./index.css";
import "./paper.css";
import Home from "./pages/Home";
// import Blog from "./pages/Blog";
import About from "./pages/About";
import Layout from "./pages/Layout";
import DarkModeProvider from "./components/DarkModeProvider";

const App = () => {
    useEffect(() => {
        inject();
    }, []);

    return (
        <DarkModeProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path="about" element={<About />} />
                        {/*<Route path="blog" element={<Blog />} />*/}
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </DarkModeProvider>
    );
};

export default App;
