import React from "react";
import { Link } from "react-router-dom";
import DarkModeSwitch from "../components/DarkModeSwitch";
import config from "../data.json";
import HomeLogo from "../components/HomeLogo";
import Badge from "../components/Badge";
import { Tooltip } from "react-tooltip";

const Home = () => {
    return (
        <>
            <div className="home-dark-mode">
                <DarkModeSwitch />
            </div>
            <div className="home-container">
                <img
                    src={"casey.jpeg"}
                    alt="I'm the monkey on the left"
                    title="I'm the monkey on the left"
                    className="shadow row home-image"
                />

                <h2>
                    {config.home_text.map((text, i) => {
                        return i % 2 === 0 ? (
                            text
                        ) : (
                            <Badge
                                text={text}
                                key={text}
                                secondary={false}
                                className="logo-badge primary"
                            />
                        );
                    })}
                </h2>

                <h3>
                    Check out more{" "}
                    <Link to="/about">
                        <Badge text="about me" className="badge-links bounce" />
                    </Link>
                </h3>
                {/*<h3>Check out my <Link to="/blog"><span className="badge shadow badge-links ">blog</span></Link> or more <Link to="/about"><span className="badge shadow badge-links ">about me</span></Link></h3>*/}

                <div className="row">
                    {config.home_logos.map((data) => (
                        <HomeLogo data={data} key={data.logo} />
                    ))}
                </div>
            </div>
            {config.home_logos.map((data) => (
                <Tooltip
                    id={data.href}
                    key={data.href}
                    place="bottom"
                    content={data.link_short}
                />
            ))}
        </>
    );
};

export default Home;
