import React from "react";
import ExternalLink from "./ExternalLink";

const HomeLogo = ({ data }) => {
    return (
        <ExternalLink href={data.href} className="logo-links">
            <i
                data-tooltip-id={data.href}
                className={`${data.logo} margin-small badge-links shadow home-logo`}
            />
        </ExternalLink>
    );
};

export default HomeLogo;
